import {createApp} from 'vue'
import {createHead} from '@vueuse/head'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import firebase from '@/plugins/firebase/config'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import moshaToast from 'mosha-vue-toastify'
import mixins from '@/plugins/common'
import 'vue-neat-modal/dist/vue-neat-modal.css'
import Cleave from 'vue-cleave-component';
//import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import LoadingPlugin from "vue-loading-overlay/dist/vue-loading";
import 'vue-loading-overlay/dist/vue-loading.css'

const app = createApp(App);
const head = createHead();
app.config.globalProperties.$firebase = firebase;
window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
window.$ = $.extend(require('jquery-ui-bundle'));

const vueProgressBarOptions = {
    color: "#bffaf3",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};
app
        .use(store)
        .use(VueProgressBar, vueProgressBarOptions)
        .use(moshaToast)
        .use(LoadingPlugin)
        .use(head)
        .use(router)
        .use(VueAxios, axios)
        .use(Cleave)
        .mixin(mixins)
        .mount('#app')
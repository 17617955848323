import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBYuQoBEZW3-hmQUH7AckGy5JFxe--xj7s",
    authDomain: "kt-sales-strategy-store.firebaseapp.com",
    databaseURL: "https://kt-sales-strategy-store-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "kt-sales-strategy-store",
    storageBucket: "kt-sales-strategy-store.appspot.com",
    messagingSenderId: "954860817604",
    appId: "1:954860817604:web:a543f76d7c2b32846dcd91",
    measurementId: "G-QNFPQRC983"
    /*
    apiKey: "AIzaSyBJ_cMv9AgYYNSxieIwkMFlP4Rcp99F02c",
    authDomain: "kt-sales-dev.firebaseapp.com",
    databaseURL: "https://kt-sales-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "kt-sales-dev",
    storageBucket: "kt-sales-dev.appspot.com",
    messagingSenderId: "1069468854338",
    appId: "1:1069468854338:web:ff1f7a421df960348525db"
    */
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
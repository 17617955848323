import { createRouter, createWebHistory } from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'
import store from "@/store"
import meta from '@/router/meta.json'

// Create <RouterLayout> component.
const RouterLayout = createRouterLayout(layout => {
    // Resolves a layout component with layout type string.
    return import('@/layouts/' + layout + '.vue')
})

function route(path, view){
    view = view.includes('.vue') ? view : view + '.vue';
    let _meta = meta[path] ? meta[path] : {};
    Object.assign(_meta, {
        progress: {
            func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "0.2s", opacity: "0.6s", termination: 200 },
                },
            ],
        },
    });
    return {
        path: path,
        component: () => import(`@/pages/${view}`),
        meta: _meta
    }
}
const children = [
    route('/login', 'new-login'),
    route('/', 'firebase/channel/new-index'),
    route('/channel', 'firebase/channel/new-index'),
    route('/channel/create/:template', 'firebase/channel/new-create'),
    route('/channel/edit/:hash', 'firebase/channel/new-edit'),
    //route('/channel/modify/:hash', 'firebase/channel/create'),
    //route('/vmd/:hash', 'firebase/vmd/new-index'),
    /*route('/vmd', 'firebase/vmd/index'),
    route('/vmd/create', 'firebase/vmd/create'),
    route('/vmd/modify/:hash', 'firebase/vmd/create'),*/
    route('/mainVideo', 'firebase/mainVideo/new-index'),
    //route('/mainVideo/create', 'firebase/mainVideo/create'),
    route('/subMenu', 'firebase/subMenu/hotDeal/new-index'),
    route('/subMenu/hotDeal', 'firebase/subMenu/hotDeal/new-index'),
    route('/subMenu/halfPrice', 'firebase/subMenu/halfPrice/new-index'),
    route('/subMenu/cards', 'firebase/subMenu/cards/new-index'),
    // route('/subMenu/cards/create', 'firebase/subMenu/cards/create'),
    route('/subMenu/products', 'firebase/subMenu/products/new-index'),
    route('/subMenu/biz', 'firebase/subMenu/biz/index'),
    // route('/subMenu/products/create', 'firebase/subMenu/products/create'),
    route('/new-login', 'new-login'),
    // route('/channeltest', 'firebase/channel/new-index'),
    // route('/videotest','firebase/mainVideo/new-index'),
    // route('/subMenu/hotDealtest','firebase/subMenu/hotDeal/new-index'),
    // route('/subMenu/halfPricetest','firebase/subMenu/halfPrice/new-index'),
    // route('/subMenu/cardstest','firebase/subMenu/cards/new-index'),
    // route('/subMenu/productstest','firebase/subMenu/products/new-index'),
    // route('/vmdtest','firebase/vmd/new-index'),
    route('/smart','firebase/smart/index'),
    route('/samsung','firebase/smartSamsung/index'),
    route('/monitorvmd', 'firebase/monitorvmd/index'),
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',

            // Pass <RouterLayout> as the route component
            component: RouterLayout,

            // All child components will be applied with corresponding layout component
            children: children
        }
    ]
});

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            next('/login')
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})


export default router;

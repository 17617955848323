import {createStore} from 'vuex'
import firebase from 'firebase/app';
import 'firebase/auth';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

export default createStore({
    state: {
        uid: '',
        reCaptchaSiteKey: '6Lf0fh4cAAAAAD8wtqoEDV-MVH1hq9tLQsPQSF7x',
        reCaptchaSecrectKey: '6Lf0fh4cAAAAAP_1Nfn4k6YHa2Euqawx4KoQTz_z',
    },
    getters:{
        isLoggedIn: state => {
            return state.uid !== undefined && state.uid != null && state.uid.trim().length > 0;
        }
    },
    mutations: {
        LOGIN(state, uid) {
            state.uid = uid;
        },
        LOGOUT(state) {
            state.uid = null
        }
    },
    actions: {
        LOGIN({commit}, {email, password}) {
            return firebase.auth().signInWithEmailAndPassword(email, password).then(
                    fb => {
                        commit("LOGIN", fb.user.uid);
                    },
                    err => {
                        return err;
                    });
            /*return axios
                    .post(`${resourceHost}/login`, { email, password })
                    .then(({ data }) => commit("LOGIN", data))*/
        },
        LOGOUT({commit}) {
            commit("LOGOUT")
        },
    },
    modules: {},
    plugins: [createPersistedState({
        storage: {
            getItem: key => Cookies.get(key),
            setItem: (key, value) => Cookies.set(key, value, { expires: 7, secure: true }),
            removeItem: key => Cookies.remove(key)
        }
    })]
})

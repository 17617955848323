import router from "@/router";
import moment from "moment";
import {createToast} from "mosha-vue-toastify";
/*import {useLoading} from 'vue3-loading-overlay';*/

const mixins = {
    methods: {
        redirect(pageURL, params) {
            let pageContext = {};
            if (pageURL.startsWith('/')) {
                pageContext.path = pageURL;
            } else {
                pageContext.name = pageURL;
            }
            if (params) {
                pageContext.params = params;
            }
            this.$router.push(pageContext).catch(function () {
                router.go(0);
            });
        },
        printAsPrice(num) {
            if (isNaN(Number(num)) || !num) {
                num = 0;
            }
            return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        },
        isNumeric: function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
        arrayIndexExists(arr, index) {
            if (arr && Array.isArray(arr) && arr.length > 0 && index < arr.length) {
                if (typeof arr[index] !== 'undefined') {
                    return true;
                }
            }
            return false;
        },
        isImage(file) {
            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (file) {
                if (file instanceof File) {
                    if (acceptedImageTypes.includes(file['type'])) {
                        return true;
                    }
                } else if (typeof file === 'string') {
                    let find = acceptedImageTypes.find((type) => {
                        let ext = type.replace('image/', '');
                        return file.toLowerCase().includes(ext);
                    });
                    return find;
                }
            }
            return false;
        },
        isVideo(file) {
            const acceptedVideoTypes = ['video/mp4'];
            if (file) {
                if (file instanceof File) {
                    if (acceptedVideoTypes.includes(file['type'])) {
                        return true;
                    }
                } else if (typeof file === 'string') {
                    let find = acceptedVideoTypes.find((type) => {
                        let ext = type.replace('video/', '');
                        return file.toLowerCase().includes(ext);
                    });
                    return find;
                }
            }
            return false;
        },
        getFile(data) {
            if (data instanceof File) {
                return URL.createObjectURL(data);
            } else if (typeof data === 'string') {
                return data;
            }
            return null;
        },
        timeToString(m) {
            return moment(m).format('YYYY-MM-DD HH:mm:ss');
        },
        toastSuccess(msg) {
            createToast({
                title: '',
                description: msg
            }, {
                position: 'top-center',
                transition: 'slide',
                timeout: 5000,
                type: 'success',
                hideProgressBar: true,
                onClose: () => {

                }
            })
        },
        toastDanger(msg) {
            createToast({
                title: '',
                description: msg
            }, {
                position: 'top-center',
                transition: 'slide',
                timeout: 3000,
                type: 'danger',
                hideProgressBar: true,
                onClose: () => {

                }
            })
        },
        loadingOverlay(success) {
            let loader = this.$loading.show({
                // Pass props by their camelCased names
                color: '#282828',
                loader: 'dots',
                width: 128,
                height: 128,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            });
            success(loader);
        },
    }
}

export default mixins;